import { ConfigWrapper } from "./config-wrapper";

export const defaultAccountManagementConfig = {
  manageProofsUrl: "https://account.live.com.chrisshep.sheptest.bt.myshn.eu/proofs/manage",
};

const accountManagementConfig = new ConfigWrapper(defaultAccountManagementConfig);

/**
 * The config wrapper that should be used to access account management config properties
 */
export default accountManagementConfig;
